<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <!-- <v-card class="card-shadow border-radius-xl">
        <v-card-title>Batch Number Tracking</v-card-title>
        <v-card-text class="px-0 py-0">
          <v-row class="ma-5">
            <v-col>
              <label class="text-md text-typo font-weight-bolder ms-1"
                ><span class="red--text"><strong>* </strong></span>Batch
                No</label
              >
              <v-form ref="frm">
                <v-text-field
                  v-model="batch_no"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-2
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Enter Batch No"
                  :rules="[(v) => !!v || 'Batch No is required']"
                  persistent-hint
                ></v-text-field> </v-form
            ></v-col>
            <v-col
              ><v-btn
                @click="clearFilter"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-capitalize
                  btn-ls btn-secondary
                  bg-light
                  py-3
                  px-6
                  mr-2
                  mt-8
                "
                >Clear</v-btn
              >

              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                dark
                class="
                  text-capitalize
                  btn-ls btn-primary
                  bg-success
                  py-3
                  px-6
                  mt-8
                "
                @click="download"
                >Download</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card> -->
      <v-card class="card-shadow border-radius-xl"
        ><v-card-title>PO Details</v-card-title>
        <v-card-text class="px-0 py-0">
          <v-form ref="frm_PO">
            <v-row class="ma-5">
              <v-col v-for="(p, i) in purchaseType" :key="i" cols="3">
                <v-checkbox
                  v-model="selected"
                  :label="p"
                  color="red"
                  :value="p"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row class="ma-5">
              <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                <label class="text-md text-typo font-weight-bolder ms-1"
                  ><span class="red--text"><strong>* </strong></span>From (Start
                  date)</label
                >

                <v-menu
                  ref="start_date_menu"
                  v-model="start_date_menu"
                  :close-on-content-click="false"
                  :return-value.sync="start_date_menu"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="start_date"
                      v-bind="attrs"
                      v-on="on"
                      hide-details="auto"
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        input-icon
                        mt-5
                      "
                      dense
                      flat
                      filled
                      solo
                      height="46"
                      placeholder="Start Date"
                      persistent-hint
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    color="green lighten-1"
                    header-color="primary"
                    v-model="start_date"
                    :max="end_date"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="start_date_menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.start_date_menu.save(start_date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                <label class="text-md text-typo font-weight-bolder ms-1"
                  ><span class="red--text"><strong>* </strong></span>To (End
                  date)</label
                >

                <v-menu
                  ref="end_date_menu"
                  v-model="end_date_menu"
                  :close-on-content-click="false"
                  :return-value.sync="end_date_menu"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="end_date"
                      v-bind="attrs"
                      v-on="on"
                      hide-details="auto"
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        input-icon
                        mt-5
                      "
                      dense
                      flat
                      filled
                      solo
                      height="46"
                      placeholder="To Date"
                      persistent-hint
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    color="green lighten-1"
                    header-color="primary"
                    v-model="end_date"
                    :min="start_date"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="end_date_menu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.end_date_menu.save(end_date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu> </v-col
              ><v-col
                ><v-btn
                  @click="clearPoFilter"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="
                    font-weight-bold
                    text-capitalize
                    btn-ls btn-secondary
                    bg-light
                    py-3
                    px-6
                    mr-2
                    mt-10
                  "
                  >Clear</v-btn
                >

                <v-btn
                  elevation="0"
                  :ripple="false"
                  height="43"
                  dark
                  class="
                    text-capitalize
                    btn-ls btn-primary
                    bg-success
                    py-3
                    px-6
                    mt-10
                  "
                  @click="downloadPoDetails"
                  >Download</v-btn
                >
              </v-col></v-row
            ></v-form
          >
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import api from "../api";

export default {
  data() {
    return {
      overlay: false,
      batch_no: "",
      start_date_menu: false,
      end_date_menu: false,
      start_date: null,
      end_date: null,
      purchaseType: ["AUCTION", "PRIVATE", "INTERNAL TRANSFER"],
      selected: [],
    };
  },
  created() {
    this.setDates();
    this.clearFilter();
  },
  methods: {
    clearFilter() {
      this.batch_no = "";
      return this.$refs.frm.resetValidation();
    },
    async setDates() {
      this.clearPoFilter();

      let date = new Date();
      let d = new Date(date.getFullYear(), date.getMonth(), 1);
      let d1 = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.start_date = this.formatDate(d);
      this.end_date = this.formatDate(d1);
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },
    clearPoFilter() {
      this.setDates();
      return this.$refs.frm_PO.resetValidation();
    },
    validate() {
      return this.$refs.frm.validate();
    },
    validatePO() {
      return this.$refs.frm_PO.validate();
    },
    async download() {
      this.clearPoFilter();
      if (this.validate()) {
        this.overlay = true;
        await api.download(this.batch_no);
        this.overlay = false;
      }
    },
    async downloadPoDetails() {
      // this.clearFilter();
      // if (this.validatePO()) {
      // console.log(this.selected);
      this.overlay = true;
      let ptype = "";

      await api.downloadPoDetails(
        this.start_date,
        this.end_date,
        this.selected
      );
      this.overlay = false;
      // }
    },
  },
  computed: {},
  watch: {},
};
</script>

<style></style>
